import React from 'react';

import clsx from 'clsx';
import { CustomerAddress } from '@sus-core/components/form/address/AddressEditor';
import { Button } from '@sus-core/components/button/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { useCountries } from '@sus-core/hooks/store/useCountries';

type Address = CustomerAddress;

export type AddressViewProps = {
  className?: string;
  title?: string;
  editLabel?: string;
  address?: Address;
  deleting?: boolean;
  updating?: boolean;
  onClick?: (address: Address) => void;
  onEditClick?: (address: Address) => void;
  onDeleteClick?: (address: Address) => void;
};

export function AddressView({
  className,
  address,
  title,
  onEditClick,
  onDeleteClick,
  onClick,
  editLabel,
  deleting,
  updating,
}: AddressViewProps) {
  const { resolveById } = useCountries();
  return (
    <div
      onClick={() => onClick?.(address)}
      className={clsx(
        'relative p-2 flex flex-col b-box overflow-x-hidden',
        className
      )}>
      {title && <h4>{title}</h4>}
      <span>
        {address?.firstname} {address?.lastname}
      </span>
      <span>{address?.street?.join(' ')}</span>
      <span>
        {address?.postcode} {address?.city}
      </span>
      <span>{resolveById(address?.country_code)?.full_name_locale}</span>
      <span>{address?.telephone}</span>
      <div className="mt-auto flex flex-col gap-y-2 pt-8">
        {onEditClick && (
          <Button
            loading={updating}
            size={2}
            variant="secondary"
            className="mt-auto p-2 cursor-pointer"
            onClick={() => onEditClick(address)}>
            {editLabel || 'bearbeiten'}
          </Button>
        )}
        {onDeleteClick && (
          <Button
            confirm="Wirklich löschen?"
            loading={deleting}
            size={2}
            variant="bordered"
            className="p-2 cursor-pointer"
            onClick={() => {
              onDeleteClick(address);
            }}>
            <FontAwesomeIcon icon={faTrash} className="mr-4" />
            löschen
          </Button>
        )}
      </div>
    </div>
  );
}

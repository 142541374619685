import React, { useEffect, useRef, useState } from 'react';

import { useCartService } from '@sus-core/state/xstate/cart/useCartService';

import { FormField } from '../form/FormField';
import { StepForm } from './StepForm';
import { StepProps } from './StepProps';
import { Button } from '../button/Button';
import { restApi } from '@sus-core/api/client/sus-rest-client';
import { GetCart_cart } from 'src/generated/api.types';
import { notifySuccess } from '@sus-core/utils/notify';
import {
  CHECKOUT_STEP_NUMBERS,
  useTracking,
} from '@sus-core/hooks/tracking/useTracking';

export function ShippingStep({ onComplete }: StepProps) {
  const { content, setShippingMethod } = useCartService();
  const [loading, setLoading] = useState(false);

  const stepTracked = useRef(false);
  const tracking = useTracking();
  useEffect(() => {
    if (content?.items && !stepTracked.current) {
      tracking.checkoutStep(CHECKOUT_STEP_NUMBERS.SHIPPING, content.items);
      stepTracked.current = true;
    }
  }, [content?.items]);

  const shippingAddress = content?.shipping_addresses?.[0];

  const selectedCarrierCode =
    shippingAddress?.selected_shipping_method?.carrier_code;
  const selectedMethodCode =
    shippingAddress?.selected_shipping_method?.method_code;

  const handleSubmitShipping = values => {
    const [carrier_code, method_code] = values['shipping'].split(',');

    const shippingChanged =
      selectedCarrierCode !== carrier_code ||
      selectedMethodCode !== method_code;

    if (shippingChanged) {
      setLoading(true);
      setShippingMethod([{ carrier_code, method_code }])
        .then(() => {
          onComplete();
        })
        .finally(() => setLoading(false));
    } else {
      onComplete();
    }
  };

  return (
    <section className="flex flex-row items-start">
      <ShipmentMethodForm loading={loading} onSubmit={handleSubmitShipping} />
    </section>
  );
}

function ShipmentMethodForm({ onSubmit, loading }) {
  const { content, cartId } = useCartService();

  const shippingAddress = content?.shipping_addresses?.[0];

  const hasShippingOptions =
    shippingAddress?.available_shipping_methods?.length > 0;

  return (
    <StepForm
      loading={loading}
      hideSubmit={!hasShippingOptions}
      disableSubmit={formState => !formState.isValid}
      onSubmit={hasShippingOptions ? onSubmit : null}
      submitLabel="Weiter zu Zahlung">
      {!shippingAddress && (
        <>
          <p>Bitte geben Sie zuerst die Versandadresse ein.</p>
          <FormField name="shipping" className="hidden" label="">
            <input
              type="text"
              hidden
              readOnly
              checked={!!shippingAddress}
              required
            />
          </FormField>
        </>
      )}
      <div className="b-box">
        {shippingAddress && (
          <div className="my-4">
            <h3>Versand nach:</h3>
            <br />
            {shippingAddress.street}
            <br />
            {shippingAddress.postcode} {shippingAddress.city}
            <br />
            {shippingAddress.country.label}
            <br />
          </div>
        )}
        {!hasShippingOptions && (
          <div>
            <p>Die Versandkosten ins Ausland sind Tagespreis abhängig.</p>
            <p>
              Bitte erfragen Sie diese per Email unter
              office@schrank-und-stuhl.de oder telefonisch unter 0049 30 364 344
              70.
            </p>
            <p>
              Oder schicken Sie uns eine Anfrage mit dem Inhalt Ihres
              Warenkorbes. Wir werden uns umgehend bei Ihnen melden.
            </p>

            <RequestShippingQuoteButton />
          </div>
        )}
        {hasShippingOptions && <hr className="border-gray-4" />}
        {hasShippingOptions && <h3>Versandoptionen</h3>}
        {shippingAddress?.available_shipping_methods?.map(method => (
          <FormField
            name="shipping"
            layout="row"
            className="flex-row-reverse items-center"
            labelClass="mr-auto ml-4"
            noHint
            key={method.carrier_code}
            label={[method.method_title || method.carrier_title]
              .filter(_ => _)
              .join(' | ')}>
            <input
              type="radio"
              defaultChecked={
                shippingAddress?.available_shipping_methods?.length === 1
              }
              value={[method.carrier_code, method.method_code]}
            />
          </FormField>
        ))}
      </div>
    </StepForm>
  );
}

const SHIPPINGQUOTE_ERROR =
  'Beim übermitteln Ihrer Anfrage ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später nocheinmal oder kontaktieren Sie uns telefonisch.';

function getQuoteHash(content: GetCart_cart) {
  const items = content?.items
    .map(i => `${i.product.sku}-${i.quantity}`)
    .join('|');
  const shippingAddress = content?.shipping_addresses[0];

  const address = [
    shippingAddress?.firstname,
    shippingAddress?.lastname,
    shippingAddress?.company,
    shippingAddress?.city,
    shippingAddress?.postcode,
    shippingAddress?.country,
    shippingAddress?.telephone,
  ].join('|');

  return `${items}-${address}`;
}

function useShippingQuote() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const { cartId, content } = useCartService();
  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const [quoteHash, setQuoteHash] = useState<string>();

  useEffect(() => {
    if (hasRequested) {
      // compare quote previous quote date with current
      if (getQuoteHash(content) !== quoteHash) {
        setHasRequested(false);
      }
    }
  }, [content]);

  const requestQuote = () => {
    setLoading(true);
    if (error) {
      setError(null);
    }

    return restApi
      .sendShippingQuote(cartId)
      .then(r => {
        return r.json?.() || r;
      })
      .then(result => {
        if (!result) {
          setError(SHIPPINGQUOTE_ERROR);
          return Promise.reject('error');
        } else {
          setQuoteHash(getQuoteHash(content));
          setHasRequested(true);
        }
      })
      .catch(() => {
        setError(SHIPPINGQUOTE_ERROR);

        return Promise.reject('error');
      })
      .finally(() => setLoading(false));
  };

  return {
    loading,
    error,
    requestQuote,
    hasRequested,
  };
}

function RequestShippingQuoteButton() {
  const { loading, requestQuote, hasRequested } = useShippingQuote();

  const handleSendQuoteClick = () =>
    requestQuote().then(() =>
      notifySuccess(
        `Ihre Anfrage wurde übermittelt. 
        Die Versandkosten werden nun bei der Spedition erfragt. 
        Schrank & Stuhl wird sich innerhalb der nächsten ca. 2 Werktage bei Ihnen melden. 
        Bei weiteren Fragen melden Sie sich bitte unter +49 30 364 344 70.`
      )
    );

  return (
    <Button
      size={2}
      variant="primary"
      loading={loading}
      disabled={hasRequested}
      onClick={e => {
        e.preventDefault();
        handleSendQuoteClick();
      }}>
      Jetzt Anfrage schicken
    </Button>
  );
}

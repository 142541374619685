import React from 'react';
import clsx from 'clsx';
import { Link, GatsbyLinkProps } from 'gatsby';

export function CheckoutNavigationItem({
  disabled,
  label,
  children,
  active,
  ...props
}: React.PropsWithoutRef<GatsbyLinkProps<any>> & {
  disabled?: boolean;
  label?: string;
  active?: boolean;
}) {
  return (
    <Link
      {...props}
      onClickCapture={e => disabled && e.preventDefault()}
      getProps={({ isPartiallyCurrent }) => {
        const isActive = isPartiallyCurrent || active;
        return {
          className: clsx(
            'rounded-full border-default border-gray-4 group relative group w-35px h-35px flex justify-center items-center',
            isActive ? 'bg-primary text-white border-primary' : 'bg-white-1',
            disabled && 'bg-white-1 text-gray-4'
          ),
        };
      }}>
      {children}
      {label && (
        <div className="absolute left-1/2 w-max transform -translate-y-8 -translate-x-1/2 text-11 text-gray-3 transition-opacity opacity-0 group-hover:opacity-100">
          {label}
        </div>
      )}
    </Link>
  );
}

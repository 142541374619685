import { useMatch } from '@reach/router';
import clsx from 'clsx';
import { navigate } from 'gatsby-link';
import React, { ReactNode } from 'react';
import { FormState } from 'react-hook-form';
import { Button } from '../button/Button';
import { OrderSummary } from '../cart/OrderSummary';
import { CartAddresses } from '../cart/CartAddresses';
import { Form, FormProps } from '../form/Form';
import { CHECKOUT_ROUTES } from './CHECKOUT_ROUTES';
import { CheckoutNavigation } from './CheckoutNavigation';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import { CheckoutInfoBox } from '../cart/CheckoutInfoBox';
import { Link } from 'gatsby';
import { Icons } from '../icon/Icons';

export interface StepFormProps extends FormProps {
  submitLabel?: string;
  hideSubmit?: boolean;
  disableSubmit?:
    | boolean
    | ((formState: FormState<Record<string, any>>) => boolean);
  sideContent?: ReactNode;
  loading?: boolean;
}

export function StepForm({
  children,
  render,
  hideSubmit,
  submitLabel = 'Weiter',
  disableSubmit,
  className,
  sideContent,
  loading,
  ...rest
}: StepFormProps) {
  const addressActive = useMatch(CHECKOUT_ROUTES.ADDRESS);
  const orderActive = useMatch(CHECKOUT_ROUTES.ORDER);

  const { content, cartId } = useCartService();

  return (
    <Form
      {...rest}
      className={clsx('flex-1', className)}
      render={({ formState, trigger }) => {
        return !cartId ? (
          <div className="text-center">
            <p>In Ihrem Warenkorb befinden sich aktuell keine Artikel.</p>
            <Link
              className="mt-8 inline-block rounded-lg bg-blue-1 text-white-1 font-semibold px-6 py-4"
              to="/">
              weiter einkaufen
            </Link>
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row flex-wrap gap-x-16 gap-y-8">
            <div className="h-100px w-full flex flex-col md:flex-row mb-8 gap-x-16">
              <h1 className="m-0 p-0 flex-3">Kasse</h1>
              <div className="flex-2">
                <CheckoutNavigation className="mt-4 mx-12" />
              </div>
            </div>
            <div className="flex-3 gap-y-8 flex flex-col">{children}</div>
            <div className="flex-2 flex flex-col relative">
              <div className="b-box flex flex-col gap-y-4">
                <OrderSummary showPriceDetails />
                {!addressActive && (
                  <CartAddresses
                    onEdit={() => navigate(CHECKOUT_ROUTES.ADDRESS)}
                  />
                )}
                {orderActive && content?.selected_payment_method && (
                  <CheckoutInfoBox
                    title="Zahlungsmethode:"
                    onEdit={() => navigate(CHECKOUT_ROUTES.PAYMENT)}>
                    {content?.selected_payment_method.title}
                  </CheckoutInfoBox>
                )}
                {sideContent}

                {formState.isDirty && !formState.isValid && (
                  <div className="bg-danger bg-opacity-50 flex justify-center items-center p-2 rounded-md border-default border-black text-white-1">
                    <Icons.Danger className="mr-2" /> Bitte überprüfen Sie Ihre
                    Eingaben.
                  </div>
                )}
                {!hideSubmit && (
                  <Button
                    type="submit"
                    loading={loading}
                    className="w-full mt-4"
                    size={1}
                    variant="primary"
                    onClick={() => {
                      !formState.isValid && trigger();
                    }}
                    disabled={
                      disableSubmit === undefined
                        ? !formState.isValid || !formState.isDirty
                        : typeof disableSubmit === 'function'
                        ? disableSubmit(formState)
                        : disableSubmit
                    }>
                    {submitLabel}
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

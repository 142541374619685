import React, { useEffect, useRef, useState } from 'react';
import { StepForm, StepFormProps } from './StepForm';
import { StepProps } from './StepProps';
import { FormField } from '../form/FormField';

import { useCustomerService } from '@sus-core/state/xstate/customer/useCustomerService';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import { AddressEditorFields } from '../form/address/AddressEditorFields';
import { addressEquals } from '../form/util/compare';
import { EMailFormField } from '../form/field/EMailFormField';
import {
  GetCart_cart_billing_address,
  GetCart_cart_shipping_addresses,
} from 'src/generated/api.types';
import isEqual from 'lodash/isEqual';
import { useFormContext } from 'react-hook-form';
import { Modal } from '../modal/Modal';
import { AddressView } from '../user/account/AddressView';
import { CustomerAddress } from '../form/address/AddressEditor';
import { Button } from '../button/Button';
import { requiredValidation } from '../form/validationRules';
import {
  CHECKOUT_STEP_NUMBERS,
  useTracking,
} from '@sus-core/hooks/tracking/useTracking';

type AddressFormData = {
  firstname?: string;
  lastname?: string;
  city?: string;
  company?: string;
  country_code?: string;
  postcode?: string;
  street?: string[];
  telephone?: string;
};

const makeAddress = (
  a?: GetCart_cart_billing_address | GetCart_cart_shipping_addresses,
  b?: AddressFormData,
  c?: AddressFormData
): AddressFormData => {
  return {
    firstname: a?.firstname || b?.firstname || c?.firstname,
    lastname: a?.lastname || b?.lastname || c?.lastname,
    city: a?.city || b?.city || c?.city,
    company: a?.company || b?.company || c?.company,
    country_code:
      a?.country?.code || b?.country_code || c?.country_code || 'DE',
    postcode: a?.postcode || b?.postcode || c?.postcode,
    street: a?.street || b?.street || c?.street,
    telephone: a?.telephone || b?.telephone || c?.telephone,
  };
};

function useCartAddressValues() {
  const { content } = useCartService();
  const { customer, defaultBilling, defaultShipping } = useCustomerService();
  const [values, setValues] = useState<Record<string, any>>({});

  useEffect(() => {
    const email = content?.email;

    const billing = makeAddress(
      content?.billing_address,
      defaultBilling,
      customer
        ? {
            firstname: customer.firstname,
            lastname: customer.lastname,
          }
        : undefined
    );

    const shipping = makeAddress(
      content?.shipping_addresses?.[0],
      defaultShipping
    );

    const defaults = {
      billing: {
        email,
        ...(billing || {}),
      },
      shipping: {
        ...shipping,
        customer_notes: content?.shipping_addresses?.[0]?.customer_notes,
      },
    };

    if (!isEqual(defaults, values)) {
      setValues(defaults);
    }
  }, [customer, defaultBilling, defaultShipping, content, setValues]);

  return {
    defaultValues: values,
    hasDefaultBilling: !!defaultBilling,
    hasDefaultShipping: !!defaultShipping,
  };
}

export function AddressStep(props: StepProps) {
  const { isLoggedIn, containsAddress } = useCustomerService();
  const { defaultValues, hasDefaultShipping } = useCartAddressValues();
  const [loading, setLoading] = useState(false);

  const { setAddresses, content } = useCartService();

  const stepTracked = useRef(false);
  const tracking = useTracking();
  useEffect(() => {
    if (content?.items && !stepTracked.current) {
      tracking.checkoutStep(CHECKOUT_STEP_NUMBERS.ADDRESS, content.items);
      stepTracked.current = true;
    }
  }, [content?.items]);

  const handleSubmit: StepFormProps['onSubmit'] = values => {
    setLoading(true);

    const billing = {
      city: values.billing.city,
      firstname: values.billing.firstname,
      lastname: values.billing.lastname,
      street: Array.isArray(values.billing.street)
        ? values.billing.street
        : [values.billing.street],
      telephone: values.billing.telephone,
      country_code: values.billing.country_code,
      company: values.billing.company,
      postcode: values.billing.postcode,
    };

    const shipping = !values.useAsShipping
      ? {
          city: values.shipping.city,
          firstname: values.shipping.firstname,
          lastname: values.shipping.lastname,
          street: Array.isArray(values.shipping.street)
            ? values.shipping.street
            : [values.shipping.street],
          telephone: values.shipping.telephone,
          country_code: values.shipping.country_code,
          company: values.shipping.company,
          postcode: values.shipping.postcode,
        }
      : undefined;

    let saveBilling = false;
    let saveShipping = false;
    if (isLoggedIn) {
      saveBilling = !containsAddress(billing);
      saveShipping = !!shipping && !containsAddress(shipping);
    }

    setAddresses({
      email: values.billing.email,
      billing: {
        same_as_shipping: values.useAsShipping,
        address: { ...billing, save_in_address_book: saveBilling },
      },
      shipping: !values.useAsShipping
        ? [
            {
              address: { ...shipping, save_in_address_book: saveShipping },
              customer_notes: values.shipping.customer_notes,
            },
          ]
        : values.shipping.customer_notes
        ? [
            {
              address: {
                city: values.billing.city,
                firstname: values.billing.firstname,
                lastname: values.billing.lastname,
                street: Array.isArray(values.billing.street)
                  ? values.billing.street
                  : [values.billing.street],
                telephone: values.billing.telephone,
                country_code: values.billing.country_code,
                company: values.billing.company,
                postcode: values.billing.postcode,
              },
              customer_notes: values.shipping.customer_notes,
            },
          ]
        : undefined,
    })
      .then(() => {
        props.onComplete?.();
      })
      .finally(() => setLoading(false));
  };

  const addressesAreEqual = addressEquals(
    defaultValues.billing,
    defaultValues.shipping
  );

  const useBillingForShipping = addressesAreEqual || !hasDefaultShipping;

  return (
    <StepForm
      loading={loading}
      mode="all"
      criteriaMode="all"
      disableSubmit={false}
      defaultValues={defaultValues}
      submitLabel="Weiter zum Versand"
      onSubmit={handleSubmit}>
      <AddressFormContent useBillingForShipping={useBillingForShipping} />

      {/* <div className="flex-1 b-box">
        <FormField name="shipping.customer_notes" label="Anmerkungen">
          <textarea />
        </FormField>
      </div> */}
    </StepForm>
  );
}

function AddressFormContent({
  useBillingForShipping = true,
}: {
  useBillingForShipping?: boolean;
}) {
  const { setValue } = useFormContext();
  const { isLoggedIn, state } = useCustomerService();
  const [useForShipping, setUseForShipping] = useState<boolean>(
    useBillingForShipping
  );
  useEffect(() => {
    setUseForShipping(useBillingForShipping);
  }, [useBillingForShipping]);

  const handleAddressbookSelection =
    (prefix: 'billing' | 'shipping') => (address: CustomerAddress) => {
      Object.keys(address).forEach(prop =>
        setValue(`${prefix}.${prop}`, address[prop], {
          shouldDirty: true,
          shouldValidate: true,
        })
      );
    };

  const showAddressBookOption =
    isLoggedIn && state.context.customer?.addresses?.length > 0;

  return (
    <>
      <div className="flex flex-col gap-2 b-box">
        <h3>Rechnungsadresse</h3>
        {!isLoggedIn && (
          <EMailFormField name="billing.email" required={requiredValidation} />
        )}
        {showAddressBookOption && (
          <Adressbook onSelect={handleAddressbookSelection('billing')} />
        )}
        <AddressEditorFields prefix="billing" />
        <FormField
          name="useAsShipping"
          label="An diese Adresse verschicken"
          layout="row"
          className="flex-row-reverse items-center"
          labelClass="mr-auto"
          noHint>
          <input
            onChange={e => {
              setUseForShipping(e.target.checked);
            }}
            checked={useForShipping}
            type="checkbox"
          />
        </FormField>
      </div>
      {!useForShipping && (
        <>
          <hr className="my-8 border-gray-4" />
          <div className="flex flex-col gap-2 b-box">
            <h3>Lieferadresse</h3>
            {showAddressBookOption && (
              <Adressbook onSelect={handleAddressbookSelection('shipping')} />
            )}
            <AddressEditorFields prefix="shipping" />
          </div>
        </>
      )}
    </>
  );
}

function Adressbook({
  onSelect,
}: {
  onSelect?: (address: CustomerAddress) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const { state } = useCustomerService();

  return (
    <>
      <Button
        size={2}
        variant="secondary"
        onClick={e => {
          e.preventDefault();
          setOpen(!open);
        }}>
        Adresse aus Adressbuch wählen
      </Button>
      <Modal title="Adressbuch" isOpen={open}>
        <div className="flex flex-row flex-wrap justify-center gap-8">
          {state.context.customer?.addresses?.map(address => (
            <AddressView
              key={address.id}
              address={address}
              onClick={address => {
                onSelect?.(address);
                setOpen(false);
              }}
            />
          ))}
        </div>
      </Modal>
    </>
  );
}

import clsx from 'clsx';
import React, { ReactNode } from 'react';

import { CHECKOUT_STEP } from './CheckoutNavigation';
import { PaymentStep } from './PaymentStep';
import { ShippingStep } from './ShippingStep';

import { Router } from '@reach/router';
import { Route, RouteProps } from '@sus-core/router/Route';
import { StepProps } from './StepProps';
import { OrderStep } from './OrderStep';
import { PaypalPaymentConfirm } from './paypal/PaypalPaymentConfirm';
import { AddressStep } from './AddressStep';
import { navigate } from 'gatsby-link';
import { CHECKOUT_ROUTES } from './CHECKOUT_ROUTES';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import { Link } from 'gatsby';

export interface CheckoutWizardProps {
  step?: CHECKOUT_STEP;
  className?: string;
  children?: ReactNode;
}
export function Checkout({ className }: CheckoutWizardProps) {
  return (
    <section className={clsx('flex flex-col', className)}>
      <Router>
        <StepRoute
          default
          path="checkout/adresse"
          component={AddressStep}
          onComplete={() => navigate(CHECKOUT_ROUTES.SHIPPING)}
        />
        <StepRoute
          path="checkout/versand"
          component={ShippingStep}
          onComplete={() => navigate(CHECKOUT_ROUTES.PAYMENT)}
        />
        <StepRoute
          path="checkout/zahlung/*"
          component={PaymentStep}
          onComplete={() => navigate(CHECKOUT_ROUTES.ORDER)}
        />

        <StepRoute
          path="checkout/bestellen"
          component={OrderStep}
          onComplete={() => navigate('/checkout/bestell-bestaetigung')}
        />

        <StepRoute
          path="checkout/paypal_confirm"
          component={PaypalPaymentConfirm}
          onComplete={() => navigate(CHECKOUT_ROUTES.ORDER)}
        />

        <Route path="checkout/bestell-bestaetigung" component={Done} />
      </Router>
    </section>
  );
}
interface StepRouteProps extends RouteProps {
  onComplete: StepProps['onComplete'];
}
function StepRoute(props: StepRouteProps) {
  const { isCartEmpty } = useCartService();
  return isCartEmpty ? <EmptyCart /> : <Route {...props} />;
}

function EmptyCart() {
  return (
    <section className="flex flex-col justify-center">
      <p>Ihr Warenkorb ist leer</p>
      <p>
        <Link to="/">weiter einkaufen</Link>
      </p>
    </section>
  );
}

function Done() {
  return (
    <div className="text-center text-24">
      <p>Vielen Dank für Ihre Bestellung!</p>
      <p className="text-15">
        Sie erhalten in Kürze die Bestellbestätigung an die angegebene E-Mail
        Adresse.
      </p>
    </div>
  );
}

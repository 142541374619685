import React from 'react';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';

import { CheckoutNavigationItem } from './CheckoutNavigationItem';
import clsx from 'clsx';
import { CHECKOUT_ROUTES } from './CHECKOUT_ROUTES';
import { useMatch } from '@reach/router';

export enum CHECKOUT_STEP {
  Shipping = 'shipping',
  Payment = 'payment',

  Done = 'done',
}

export function CheckoutNavigation({ className }: { className?: string }) {
  const addressActive = !!useMatch(CHECKOUT_ROUTES.ADDRESS);
  const shippingActive = !!useMatch(CHECKOUT_ROUTES.SHIPPING);
  const paymentActive = !!useMatch(CHECKOUT_ROUTES.PAYMENT + '/*');
  const orderActive = !!useMatch(CHECKOUT_ROUTES.ORDER);
  return (
    <div
      className={clsx('flex justify-between items-center relative', className)}>
      <hr className="absolute left-0 right-0 border-default border-gray-4" />
      <CheckoutNavigationItem
        active={shippingActive || paymentActive || orderActive}
        label="Adresse"
        to={CHECKOUT_ROUTES.ADDRESS}>
        1
      </CheckoutNavigationItem>
      <ShippingNavItem active={paymentActive || orderActive} />
      <PaymentNavItem active={orderActive} />
      <OrderNavItem />
    </div>
  );
}

type NavItemProps = { active?: boolean };

function ShippingNavItem({ active }: NavItemProps) {
  const { content } = useCartService();

  const canActivate = !!(content?.shipping_addresses?.length > 0);

  return (
    <CheckoutNavigationItem
      active={active}
      label="Versand"
      disabled={!canActivate && !active}
      to={CHECKOUT_ROUTES.SHIPPING}>
      2
    </CheckoutNavigationItem>
  );
}
function PaymentNavItem({ active }: NavItemProps) {
  const { content } = useCartService();

  const canActivate = !!(
    content?.shipping_addresses?.length > 0 &&
    content?.shipping_addresses?.[0]?.selected_shipping_method?.carrier_code
  );

  return (
    <CheckoutNavigationItem
      active={active}
      label="Zahlung"
      disabled={!canActivate && !active}
      to={CHECKOUT_ROUTES.PAYMENT}>
      3
    </CheckoutNavigationItem>
  );
}
function OrderNavItem({ active }: NavItemProps) {
  const { content } = useCartService();

  const canActivate = !!(
    content?.shipping_addresses?.length > 0 &&
    content?.shipping_addresses?.[0]?.selected_shipping_method?.carrier_code &&
    content?.selected_payment_method?.code
  );
  return (
    <CheckoutNavigationItem
      active={active}
      label="Bestellen"
      disabled={!canActivate && !active}
      to="/checkout/bestellen">
      4
    </CheckoutNavigationItem>
  );
}

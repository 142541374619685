import { useStaticQuery, graphql } from 'gatsby';
import { useCallback, useEffect } from 'react';

export function useCountries() {
  const data = useStaticQuery<GatsbyTypes.CountriesQuery>(graphql`
    query Countries {
      sus {
        countries {
          id
          full_name_locale
          full_name_english
          three_letter_abbreviation
          two_letter_abbreviation
          available_regions {
            code
            id
            name
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (data.sus.countries) {
      (data.sus.countries as GatsbyTypes.SUS_Country[]).sort((a, b) =>
        a.full_name_locale?.localeCompare(b.full_name_locale)
      );
    }
  }, [data?.sus?.countries]);

  const resolveById = useCallback(
    (id: string) => data?.sus?.countries?.find(c => c.id === id),
    [data]
  );

  return { countries: data.sus.countries, resolveById };
}

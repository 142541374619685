import { PAYPAL_EXPRESS_ID } from '@sus-core/constants/payment_methods';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import { cartApi } from '@sus-core/api/service/cart';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { FormField } from '../form/FormField';
import { StepForm } from './StepForm';
import { StepProps } from './StepProps';
import { CHECKOUT_ROUTES } from './CHECKOUT_ROUTES';
import {
  CHECKOUT_STEP_NUMBERS,
  useTracking,
} from '@sus-core/hooks/tracking/useTracking';

export function PaymentStep({
  onComplete,
}: { children: ReactNode } & StepProps) {
  const { content, cartId } = useCartService();
  const [selectedMethod, setSelectedMethod] = useState<string>();
  const [loading, setLoading] = useState(false);

  const stepTracked = useRef(false);
  const tracking = useTracking();
  useEffect(() => {
    if (content?.items && !stepTracked.current) {
      tracking.checkoutStep(CHECKOUT_STEP_NUMBERS.PAYMENT, content.items);
      stepTracked.current = true;
    }
  }, [content?.items]);

  const handleChange = change => {
    setSelectedMethod(change.paymentmethod);
  };

  useEffect(() => {
    if (content?.selected_payment_method) {
      setSelectedMethod(content.selected_payment_method.code);
    }
  }, [content?.selected_payment_method]);

  const { setPaymentMethod } = useCartService();

  const handleSubmit = () => {
    switch (selectedMethod) {
      case PAYPAL_EXPRESS_ID:
        return toPaypal();
      default:
        setLoading(true);
        return setPaymentMethod({
          code: selectedMethod,
        })
          .then(() => onComplete())
          .finally(() => setLoading(false));
    }
  };

  const toPaypal = () => {
    // request token
    setLoading(true);
    cartApi
      .createPaypalExpressToken({
        cart_id: cartId,
        code: PAYPAL_EXPRESS_ID,
        urls: {
          cancel_url: CHECKOUT_ROUTES.PAYMENT.substring(1), //'checkout/zahlung',
          return_url: CHECKOUT_ROUTES.PAYPAL_CONFIRM, //'checkout/paypal_confirm',
          // success_url: 'checkout/zahlung/success',
          // pending_url: 'checkout/zahlung/pending'
        },
      })
      .then(result => {
        window.location.href =
          result.data.createPaypalExpressToken.paypal_urls.start;
      })
      .finally(() => setLoading(false));
  };

  const submitLabel =
    selectedMethod === PAYPAL_EXPRESS_ID
      ? 'Weiter zu Paypal'
      : 'Weiter zur Bestellung';

  return (
    <StepForm
      loading={loading}
      disableSubmit={formState => !formState.isValid}
      defaultValues={{
        paymentmethod: selectedMethod,
      }}
      submitLabel={submitLabel}
      onChange={handleChange}
      onSubmit={handleSubmit}>
      <div className="b-box">
        <h3>Zahlungsmethode</h3>
        <div>Bitte wählen Sie Ihre favorisierte Zahlungsmethode aus:</div>
        {content?.available_payment_methods?.map(paymentMethod => (
          <FormField
            layout="row"
            name="paymentmethod"
            key={paymentMethod.code}
            label={paymentMethod.title}
            className="flex-row-reverse items-center py-8 border-b-default border-gray-4 last:border-0"
            labelClass="mr-auto ml-8">
            <input required type="radio" value={paymentMethod.code} />
          </FormField>
        ))}
      </div>
    </StepForm>
  );
}

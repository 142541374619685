import React, { useCallback, useState } from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import debounce from 'lodash/debounce';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import { GetCart_cart_items } from 'src/generated/api.types';
import { RootLink } from '@sus-core/components/navigation/RootLink';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import { Button } from '@sus-core/components/button/Button';
import { PriceValue } from '@sus-core/components//product/price/PriceValue';

import { renderCartItemOptions } from './renderCartItemOptions';
import { AmountInput } from './AmountInput';
import { useCartProductImages } from '@sus-core/hooks/useCartProductImages';

export function CartItemRow({ item }: { item: GetCart_cart_items }) {
  const { removingProduct, remove } = useCartService();
  const allImageData = useCartProductImages();
  const productImage = allImageData.find(
    prod => prod.node.sku === item.product.sku
  );

  const handleRemove = () => {
    remove(item);
  };

  const removing = removingProduct === parseInt(item.id);

  return (
    <div className="relative flex flex-row py-10 border-b-default border-gray-4 last:border-0">
      <div className="mx-auto w-50px">
        {productImage && (
          <GatsbyImage
            backgroundColor="white"
            loading="lazy"
            image={getImage(productImage.node.image as any)}
            alt={item.product.name || ''}
          />
        )}
      </div>
      <div className="ml-4 flex flex-wrap gap-4 items-center w-full">
        <RootLink
          className="text-gray-1 font-semibold block w-full"
          to={item.product.canonical_url}>
          {item.product.name}
        </RootLink>

        {renderCartItemOptions(item)}

        <div className="text-17">
          <div className="text-13 text-gray-2">Einzelpreis</div>
          <PriceValue value={item.prices.price} />
        </div>
        <div className="text-17">
          <div className="text-13 text-gray-2">Zwischensumme</div>
          <PriceValue value={item.prices.row_total} />
        </div>

        <div className="flex ml-auto flex-wrap">
          <CartItemAmount item={item} />

          <Button
            className="ml-4 mt-4 text-11 px-2 py-1 hover:text-gray-1 text-gray-3"
            size={null}
            variant="bordered"
            onClick={handleRemove}>
            {!removing && <FontAwesomeIcon icon={faTrash} />}
            {removing && <FontAwesomeIcon icon={faSpinner} spin />}
            <span className="ml-2">Entfernen</span>
          </Button>
        </div>
      </div>
      {removing && (
        <div className="absolute top-0 bottom-0 right-0 left-0 bg-white-1 bg-opacity-80 text-gray-1 text-center flex items-center justify-center">
          <div className="p-4 bg-white-1">
            <FontAwesomeIcon icon={faSpinner} spin className="mr-4" /> Artikel
            wird aus dem Warenkorb gelöscht...
          </div>
        </div>
      )}
    </div>
  );
}

export function CartItemAmount({ item }: { item: GetCart_cart_items }) {
  const { update } = useCartService();
  const [loading, setLoading] = useState<boolean>(false);

  const updateCart = useCallback(
    debounce(amount => {
      setLoading(true);
      return update([
        { cart_item_id: parseInt(item.id), quantity: amount },
      ]).finally(() => setLoading(false));
    }, 500),
    [update, item]
  );

  return (
    <AmountInput
      disableFormContext
      quantity={item.quantity}
      loading={loading}
      onChange={amount => updateCart(amount)}
    />
  );
}

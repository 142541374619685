import { CheckoutAgreements_checkoutAgreements } from 'src/generated/api.types';
import { cartApi } from '@sus-core/api/service/cart';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import React, { useEffect, useState } from 'react';
import { CartItemsList } from '../cart/CartItemsList';
import { FormField } from '../form/FormField';
import { Expandable } from '../layout/Expandable';
import { StepForm } from './StepForm';

import { StepProps } from './StepProps';

export function OrderStep({ onComplete }: StepProps) {
  const [agreements, setAgreements] = useState<
    CheckoutAgreements_checkoutAgreements[]
  >([]);

  const { placeOrder, ordering } = useCartService();

  useEffect(() => {
    cartApi
      .getCheckoutagreements()
      .then(result => setAgreements(result.data.checkoutAgreements));
  }, []);

  const handleSubmit = () => {
    placeOrder().then(() => onComplete());
  };

  return (
    <StepForm
      loading={ordering}
      submitLabel="Bestellen"
      onSubmit={handleSubmit}
      sideContent={agreements.map(agreement => {
        const { checkbox_text, content, is_html, name, agreement_id } =
          agreement;

        return (
          <div className="my-2" key={agreement.agreement_id}>
            <Expandable title={name} className="px-4 p-2 bg-ui-02">
              {is_html ? (
                <div
                  className="w-full p-4"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              ) : (
                <textarea
                  className="w-full p-4"
                  rows={20}
                  defaultValue={content}
                />
              )}
            </Expandable>

            <FormField
              layout="row"
              name={name}
              className="flex-row-reverse p-2"
              label={checkbox_text}>
              <input required type="checkbox" />
            </FormField>
          </div>
        );
      })}>
      <CartItemsList />
    </StepForm>
  );
}

import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import clsx from 'clsx';
import React, { ReactElement, ReactNode, useState } from 'react';

export function Expandable({
  title,
  defaultExpand = false,
  children,
  className,
}: {
  title?: ReactNode | ReactElement;
  defaultExpand?: boolean;
  children?: ReactNode;
  className?: string;
}) {
  const [expand, setExpand] = useState<boolean>(defaultExpand);
  return (
    <>
      <div
        className={clsx(
          'flex flex-row justify-between items-center',
          className
        )}
        onClick={() => setExpand(!expand)}>
        {title}
        <FontAwesomeIcon
          className="text-ui-06 ml-4 mr-2"
          icon={expand ? faChevronUp : faChevronDown}
        />
      </div>
      {expand && children}
    </>
  );
}

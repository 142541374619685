import {
  GetCart_cart_items,
  GetCart_cart_items_SimpleCartItem,
} from 'src/generated/api.types';
import React from 'react';
import { formatPrice } from '@sus-core/utils/price';

export function renderCartItemOptions(
  item: GetCart_cart_items
): React.ReactNode {
  switch (item.__typename) {
    case 'SimpleCartItem': {
      const simpleCartItem = item as GetCart_cart_items_SimpleCartItem;
      const hasOptions = simpleCartItem.customizable_options.length > 0;

      return hasOptions ? (
        <div className="grid gap-2 grid-cols-1 text-13 w-full">
          <div className="col-span-full underline">Gewählte Optionen:</div>
          {simpleCartItem.customizable_options.map(option => (
            <React.Fragment key={option.id}>
              <span className="font-medium mr-2">{option.label}:</span>

              <span className="w-full grid gap-1 grid-cols-[1fr,auto]">
                {option.values.map(optVal => (
                  <React.Fragment key={optVal.id}>
                    <span>{optVal.label}</span>
                    <span>
                      {optVal.price.value
                        ? formatPrice(optVal.price.value)
                        : ''}
                    </span>
                  </React.Fragment>
                ))}
              </span>
            </React.Fragment>
          ))}
        </div>
      ) : null;
    }
  }
  return null;
}

import { navigate, Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { PAYPAL_EXPRESS_ID } from '@sus-core/constants/payment_methods';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';

import React, { useEffect, useState } from 'react';

import { getQueryParams } from '../../../utils/getQueryParams';
import { StepProps } from '../StepProps';

const timeout = 10000;
const tick = 1000;

export function PaypalPaymentConfirm({ onComplete }: StepProps) {
  // get token and payer ID from query string
  const location = useLocation();
  const [error, setError] = useState<string>();
  const [timerCount, setTimerCount] = useState<number>();

  const searchParams = getQueryParams(location);

  const { setPaymentMethod, loading } = useCartService();

  useEffect(() => {
    if (error) {
      setTimerCount(timeout);
      const timeoutId = setTimeout(() => {
        navigate('/checkout/zahlung');
      }, timeout);

      const intervalId = setInterval(() => {
        setTimerCount(count => count - tick);
      }, tick);

      return () => {
        clearTimeout(timeoutId);
        clearInterval(intervalId);
      };
    }
  }, [error]);

  useEffect(() => {
    const { token, PayerID } = searchParams;

    if ((!token || !PayerID) && !loading) {
      setError(
        'Leider konnten ihre Zahlungsinformationen nicht verarbeitet werden.'
      );
    }

    if (token && PayerID && !loading) {
      setError(undefined);
      setPaymentMethod({
        code: PAYPAL_EXPRESS_ID,
        paypal_express: {
          payer_id: PayerID,
          token,
        },
      })
        .then(() => {
          onComplete();
        })
        .catch(err => {
          setError('Bei der Verarbeitung ist ein Fehler aufgetreten.');
        });
    }
  }, [loading]);

  return (
    <div className="text-center text-17">
      {!error ? (
        <>
          <p>Die Zahlungsinformationen werden verarbeitet.</p>
          <p>Bitte haben Sie etwas Geduld.</p>
        </>
      ) : (
        <>
          <p>{error}</p>
          {timerCount > 0 && (
            <p>Sie werden in {timerCount / 1000} Sekunden weitergeleitet.</p>
          )}
          <Link to="/checkout/zahlung"> Zur Zahlungsseite </Link>
        </>
      )}
    </div>
  );
}

import Layout from '@sus-core/components/scaffolding/Layout';
import { PageProvider } from '@sus-core/hooks/page/PageContext';
import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';
import React from 'react';

import { Checkout } from '../shared/components/checkout/Checkout';

export default function CheckoutPage({ location, pageContext }: SusPageProps) {
  return (
    <PageProvider
      data={{
        pageType: 'Checkout',
        attributeMetaData: pageContext.attributeMetaData,
      }}>
      <Layout
        showBreadcrumbs={false}
        currentPageName="Checkout"
        location={location}
        meta={{ title: 'Schrank und Stuhl - Checkout' }}>
        <Checkout className={PAGE_CONTAINER_CSS} />
      </Layout>
    </PageProvider>
  );
}

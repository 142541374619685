import clsx from 'clsx';
import React from 'react';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import { PriceValue } from '@sus-core/components/product/price/PriceValue';
import { Expandable } from '../layout/Expandable';

import { SimpleCartItems } from './SimpleCart';
import { CartPrices } from './CartPrices';

export interface OrderSummaryProps {
  className?: string;
  showPriceDetails?: boolean;
}

export function OrderSummary({
  className,
  showPriceDetails,
}: OrderSummaryProps) {
  const { content } = useCartService();

  return (
    <div
      className={clsx('flex flex-col', className)}
      onClick={e => e.preventDefault()}>
      <h3 className="pb-2">Bestellübersicht</h3>

      {showPriceDetails && <CartPrices />}
      <Expandable
        className="border-t-default border-gray-4 py-2"
        title={
          <>
            <span>
              {content?.items ? `${content?.items?.length} Artikel` : '---'}
            </span>
            {!showPriceDetails && (
              <>
                <PriceValue
                  classes={{ value: 'ml-auto mr-1' }}
                  value={content?.prices?.subtotal_including_tax}
                />
              </>
            )}
          </>
        }>
        <SimpleCartItems />
      </Expandable>
    </div>
  );
}

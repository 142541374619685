import React from 'react';
import { FormField, FormFieldProps } from '@sus-core/components/form/FormField';
import { emailValidation } from '../validationRules';

export function EMailFormField(
  props: { name?: string } & Omit<
    FormFieldProps,
    'children' | 'label' | 'pattern'
  >
) {
  return (
    <FormField {...props} label="E-Mail" pattern={emailValidation}>
      <input type="email" name={props.name} />
    </FormField>
  );
}

import React from 'react';
import { FormField } from '@sus-core/components/form/FormField';
import { applyPrefix } from '../util/applyPrefix';
import { AvailableCountriesSelect } from '../AvailableCountriesSelect';
import { requiredValidation } from '../validationRules';

interface Props {
  prefix?: string;
}

export function AddressEditorFields({ prefix }: Props) {
  return (
    <>
      <div className="flex gap-2 flex-wrap ">
        <FormField
          label="Vorname"
          className="flex-1"
          required={requiredValidation}
          name={applyPrefix('firstname', prefix)}>
          <input type="text" />
        </FormField>
        <FormField
          label="Nachname"
          className="flex-1"
          name={applyPrefix('lastname', prefix)}>
          <input required type="text" />
        </FormField>
      </div>
      <FormField label="Firma" name={applyPrefix('company', prefix)}>
        <input type="text" />
      </FormField>

      <FormField label="Telefon" name={applyPrefix('telephone', prefix)}>
        <input required type="text" />
      </FormField>

      <FormField label="Straße" name={applyPrefix('street', prefix)}>
        <input required type="text" />
      </FormField>
      <div className="flex flex-wrap gap-2">
        <FormField label="PLZ" name={applyPrefix('postcode', prefix)}>
          <input className="max-w-200" required type="text" />
        </FormField>
        <FormField
          label="Stadt"
          className="flex-1"
          name={applyPrefix('city', prefix)}>
          <input required type="text" />
        </FormField>
      </div>
      <FormField label="Land" name={applyPrefix('country_code', prefix)}>
        <AvailableCountriesSelect required />
      </FormField>
    </>
  );
}

import React from 'react';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import { CartItemRow } from './CartItemRow';

export function CartItemsList() {
  const { content } = useCartService();
  const hasItems = content?.items?.length > 0;

  return (
    <>
      {!hasItems && (
        <div className="w-full p-8 font-semibold flex items-center justify-center">
          Es befinden sich keine Artikel im Warenkorb.
        </div>
      )}
      <div className="flex flex-col">
        {content?.items?.map(item => {
          return <CartItemRow key={item.id} item={item} />;
        })}
      </div>
    </>
  );
}

import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Icons } from '@sus-core/components/icon/Icons';

export type CheckoutBoxProps = {
  className?: string;
  title?: string;
  onEdit?: () => void;
  children?: ReactNode;
};

export function CheckoutInfoBox({
  className,
  onEdit,
  title,
  children,
}: CheckoutBoxProps) {
  return (
    <div
      className={clsx(
        className,
        'flex flex-col p-6 bg-gray-5 rounded-lg relative'
      )}>
      {onEdit && (
        <span
          onClick={onEdit}
          className="absolute right-0 top-0 mt-6 mr-6 cursor-pointer">
          <Icons.Edit />
        </span>
      )}
      {title && <span className="text-13">{title}</span>}
      {children}
    </div>
  );
}

import React from 'react';
import { useCountries } from '@sus-core/hooks/store/useCountries';

export const AvailableCountriesSelect = React.forwardRef(
  function AvailableCountriesSelect(
    props: Omit<
      React.DetailedHTMLProps<
        React.SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
      >,
      'children'
    >,
    ref
  ) {
    const { countries } = useCountries();

    return (
      <select ref={ref as any} {...props}>
        <option />
        {countries.map(country => (
          <option key={country.id} value={country.two_letter_abbreviation}>
            {country.full_name_locale}
          </option>
        ))}
      </select>
    );
  }
);

import clsx from 'clsx';
import React from 'react';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import {
  GetCart_cart_billing_address,
  GetCart_cart_shipping_addresses,
} from 'src/generated/api.types';

import { CheckoutBoxProps, CheckoutInfoBox } from './CheckoutInfoBox';
import { useCountries } from '@sus-core/hooks/store/useCountries';

export function CartAddresses({
  className,
  onEdit,
}: { className?: string } & Pick<AddressBoxProps, 'onEdit'>) {
  const { content } = useCartService();

  return (
    <>
      {content?.billing_address && (
        <AddressBox
          title="Rechnungsadresse:"
          address={content?.billing_address}
          onEdit={onEdit}
          className={clsx(
            className,
            'flex flex-col p-6 bg-gray-5 rounded-lg relative'
          )}
        />
      )}

      {content?.shipping_addresses?.map((address, idx) => (
        <AddressBox
          title="Lieferadresse:"
          key={idx}
          address={address}
          onEdit={onEdit}
          className={clsx(
            className,
            'flex flex-col p-6 bg-gray-5 rounded-lg relative'
          )}
        />
      ))}
    </>
  );
}

type AddressBoxProps = AddressProps & CheckoutBoxProps;

function AddressBox({ address, ...rest }: AddressBoxProps) {
  return (
    <CheckoutInfoBox {...rest}>
      <Address address={address} />
    </CheckoutInfoBox>
  );
}

type AddressProps = {
  address?: GetCart_cart_shipping_addresses | GetCart_cart_billing_address;
};
function Address({ address }: AddressProps) {
  const { resolveById } = useCountries();
  return (
    <>
      <span>
        {address?.firstname} {address?.lastname}
      </span>
      {address?.company && <span>{address?.company}</span>}
      <span>
        {address?.street?.join(' ')}, {address?.postcode} {address?.city}
      </span>

      <span>{resolveById(address?.country.code).full_name_locale}</span>
      {address?.telephone && <span>Tel: {address?.telephone}</span>}
    </>
  );
}
